.items-list {
    display: flex; 
    padding: 20px 0px; 
}
.text-item {
    display: flex; 
    flex-direction: column;
    align-items: top; 
    margin: 10px 20px; 
    transition: transform 450ms; 
}
.text-item:hover {
    cursor: pointer; 
    transform: scale(1.08);
}
.text-link {
    text-decoration: none;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
}
.text-card {
    /* background-color: blue;  */
    width: 180px; 
    height: 250px; 
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    /* transition: transform 450ms;  */
}

/* .text-card:hover {
    cursor: pointer; 
    transform: scale(1.08);
} */

.text-info {
    width: 150px; 
    padding: 5px 0px; 
    font-size: 16px; 
    font-weight: 500; 
}
.text-title-container {
    background-color: transparent; 
    display: flex; 
    padding: 15px; 
    width: 30px; 
    max-height: 70%; 
}
.text-title-outline {
    display: flex; 
    padding: 5px; 
    background-color: white;
}
.text-title {
    border: solid 1px #0d0d0d; 
    width: 20px; 
    /* height: 80px;  */
    padding: 5px 0px; 
    background-color: white; 
    color: black; 
    /* font-family: 'Times New Roman'; */
    line-height: 26px;
    font-size: 18px; 
    font-weight: bolder; 
    font-weight: 900; 
    /* font-weight: 900;  */
    display: flex; 
    height: auto; 
}
.text-title:hover {
    cursor: pointer; 
    font-family: Arial; 
    font-weight: bolder; 
    line-height: 26px; 
}
.text-entitle {
    color: black; 
}
.text-entitle:hover {
    cursor: pointer; 
}
.text-card-edge {
    /* width: 10px;  */
    width: 15px; 
    justify-content: right;
    border: solid 1px white; 
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
}
.text-card-line {
    border-top: solid 1.5px white; 
    margin: 20px 0px; 
    /* width: 10px;  */
    width: 15px; 
    height: 50px; 
}

.text-body {
    align-items: center;
}

.text-line {
    display: flex; 
    justify-content: center;
}

/* Text page table of content  */

.table-of-contents {
    list-style-type: none; /* Removes bullet points */
    padding-left: 0; /* Removes default padding */
  }
  
  .table-of-contents li {
    margin: 10px 0; 
  }
  
  .table-of-contents a {
    text-decoration: none; /* Removes underline from links */
    color: grey; /* Example color for the links */
  }
  
  .table-of-content {
    margin: 20px 0;
  }
  .content-btn {
    margin: 15px 0; 
  }

  .text-index {
    color: grey;
    margin-bottom: 10px; 
  }