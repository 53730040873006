.navbar {
    padding: 1rem 2rem; 
    color: black; 
    position: fixed; /* Fix the navbar at the top */
    top: 0; /* Align to the top of the page */
    left: 0; /* Align to the left of the page */
    right: 0; /* Align to the right of the page */
    z-index: 1030; /* Ensure navbar stays on top of other content */
}

.container-fluid {
    display: flex; 
    justify-content: start !important;
}

.navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    width: auto; 
  }

.navbar-collapse {
    display: flex;
    /* justify-content: space-between;  */
    justify-content: start; /* Aligns items to the start (left) */
    align-items: center; 
    width: 100%; 
}

  .navbar-logo-container {
    display: flex; 
    align-items: center;
    /* white-space: nowrap;      */
    justify-content: center;
}

.navbar-icon {
    width: 40px;
    font-size: 1.75rem; 
}

.navbar-icon:hover {
    cursor: pointer; 
    transform: scale(1.08);
    transition: transform 450ms;
}
.navbar-name {
    padding: 0; 
    margin: 0; 
    white-space: nowrap; 
}

/* Remove the border of the burger button */
.navbar-toggler {
    border: none;
    padding: 0; 
}

/* Adjustments for the toggler */
.navbar-toggler:hover, .navbar-toggler:focus, .navbar-toggler:active {
    /* Removes outline */
    outline: none; 
     /* Removes box-shadow */
    box-shadow: none;
    /* Background color when active/focused */
    /* background-color: #F5F5F5;  */
     cursor: pointer; 
     color: #F5F5F5; 
     transform: scale(1.08);
}

.nav-item {
    white-space: nowrap; 
    padding: 0; 
    margin: 0; 
}
.nav-item:hover {
    cursor: pointer; 
    transform: scale(1.08);
    transition: transform 450ms;
}

/* .navbar-item {
    padding-right: 20px; 
    white-space: nowrap; 
    display: flex; 
    align-items: center;
    vertical-align: center; 
} */

.navbar-item-link {
    text-decoration: none; 
    color: black; 
    align-items: center;
    vertical-align: center;
    font-size: 1.1rem; 
    margin-right: 20px; 
}
.navbar-item-link:hover {
    cursor: pointer; 
    transform: scale(1.08);
    transition: transform 450ms;
}
.navbar-item-link:visited {
    color: black; 
}


/* Media query for small screens (adjust the breakpoint as needed) */

@media (max-width: 991px) {

    .navbar-header {
        width: 100%; 
        justify-content: space-between;
    }
    
    .navbar-collapse {
        width: 100%; 
        justify-content: center;
        padding: 5px; 
        display: flex; 
        flex-direction: column; 
      }
      .navbar-nav {
        flex-direction: column;
        padding: 5px; 
        align-items: center;
      }
    /* Full width */ /* Center the items */
    .navbar-collapse ul {
        width: 100%; 
        justify-content: center; 
    }

    /* Don’t grow or shrink */
    .navbar-collapse ul li {
        flex: 0 0 auto; 
    }
}

  
  @media (max-width: 600px) {
    .navbar-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  
    .navbar-toggler {
      /* margin-top: 10px; */
      padding-top: 0; 
    }

    .navbar-collapse {
        justify-content: center;
        padding: 5px; 
        display: flex; 
        flex-direction: column; 
      }

      .navbar-nav {
        padding: 5px; 
        align-items: center;
      }

      .navbar-item-link {
        margin-right: 0;
      }
  }
  

  @media (max-width: 250px) {
    .navbar-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .navbar-collapse {
        justify-content: center;
        padding: 5px; 
        display: flex; 
        flex-direction: column;     
        align-items: center;
      }

      .navbar-nav {
        padding: 5px; 
        align-items: center;
      }

      .navbar-item-link {
        margin-right: 0;
      }
  }
  

