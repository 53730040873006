.list-container {
    /* margin: 20px 30px;  */
    padding: 16px 32px; 
    align-items: left;
    justify-content: left; 
}
.header {
    font-size: 25px; 
    display: flex; 
    justify-content: left; 
}
.items-list {
    list-style: none;
    display: flex; 
    /* flex-direction: row;  */
    flex-wrap: wrap; 
    justify-content: center;
}
.list-item {
    margin: 20px;
    display: flex; 
}
.item-link {
    text-decoration: none;
    color: black;
}
.item-card {
    display: flex; 
    flex-direction: column; 
    align-items: top; 
    justify-content: center; 
    width: 300px; 
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 10%);
    border: solid 1px #d0d0d0; 
    border-radius: 10px; 
    transition: transform 450ms;
}
.item-card:hover {
    cursor: pointer; 
    transform: scale(1.08);
    /* background-color: #D0F0FD; */
    /* background-color: #F5F5F5;  */
    background-color: #F8F9FA;
}
.item-image-container {
    padding: 0px; 
    min-height: 150px; 
    display: flex;
    flex-direction: row;  
    justify-content: center; 
}
.item-image {
    width: 300px; 
    max-width: 100%; 
    /* height: auto;  */
    object-fit: contain;
    border-radius: 5px; 
    background: transparent; 
}
.item-image-text {
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: center; 
    /* font-size: 100px;  */
    font-size: 90px; 
    font-weight: bolder; 
    font-family: 'Times New Roman';
}
.item-title {
    margin: 5px 0px; 
    padding: 5px 0px; 
    font-size: 20px; 
    font-weight: 500; 
    display: flex; 
    flex-direction: column;
}
.item-info {
    padding: 0px 20px 30px 20px; 
}
.item-details {
    display: flex; 
    flex-direction: column; 
}
.item-item {
    padding: 5px 0px; 
}
