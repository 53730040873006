/* General Styles */
html, body {
  height: 100%; 
  margin: 0; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #71797E; */
  background-color: rgb(254, 254, 254);
}

/* Layout with Flexbox */
body {
  display: flex; 
  flex-direction: column; 
  min-height: 100vh; /* Use viewport height to ensure full height */
  color: #000000;
}

.App {
  /* Allows the app container to fill the available space */
 /* flex-grow: 1; */
 text-align: center;
 display: flex; 
 flex-direction: column; 
 min-height: 100vh; /* Ensures it takes at least the full viewport height */
}

/* Footer Styling */
.footer {
  margin-top: auto; 
  color: gray;
}

footer p {
  font-size: 0.9rem;
  margin: 20px 0; 
}

.social-media {
  display: flex;
  justify-content: center; /* Center icons horizontally */
  gap: 25px; /* Spacing between icons */
  /* margin: 20px 0 50px 0;  */
  margin: 30px; 
}

.social-icon {
  font-size: 1.75rem; /* Icon size */
  color: #333; /* Icon color */
}

.social-icon:hover {
  color: #282c34; /* Icon color on hover */
  cursor: pointer; 
  transform: scale(1.2);
}

.btn-coffee {
  border: none; 
  border-radius: 25px; 
}

.btn-coffee:hover {
  cursor: pointer;
  color: black;
  font-weight: 600;
  transform: scale(1.1); 
}

.bmc-link {
  /* color: #555;  */
  color: black;
  text-decoration: none;
}

.App-link {
  color: #61dafb;
}

.btn {
  border-radius: 25px;
  /* padding: 5px 15px;  */
}

.btn:hover {
  background-color: #F8F9FA;
}