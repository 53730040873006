.page-wrapper {
    /* padding: 0rem 2rem;  */
    padding: 2rem; 
    height: 100%; 
    display: flex;
    flex-direction: column; 
    align-items: center; 
}

/* Page headers */

.page-header {
    font-size: 25px; 
    margin-top: 30px; 
}

.page-subheader {
    font-size: 20px;
    padding-top: 10px; 
}

/* Container and Content Styling */

.container {
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center; 
    flex: 1;
    margin: 20px 0; 
}

/* Page label */
.label-right-align {
    text-align: right !important;
}

.page-container {
    margin: 20px 0; 
}

.section-container {
    padding: 30px 60px; 
    display: flex; 
    /* flex-direction: row;  */
    flex-direction: column; 
    text-align: left;
}
.info-text {
    margin: 5px 0px; 
    padding: 10px 20px; 
    font-size: 20px; 
    font-weight: 500; 
}

/* Table */

.table-container {
    width: 60%;  
    margin: 0 auto;   
}

.table {
    width: 100%;      
    /* Centers the table on the page */  
    margin: 0 auto;    
    border-collapse: collapse; /* Removes space between borders */
    table-layout: fixed;      /* Ensures each column is the same width */
    border-color: transparent !important;  
}

.table th 
.table td {
    padding: 10px; /* Adds padding inside each cell */
    text-align: left; /* Aligns text to the left */
}

.table th {
    text-align: right; /* Aligns text in header cells to the right */
    width: 50%; /* Sets width of header cells */
}

.table td {
    text-align: left; /* Aligns text in data cells to the left */
    width: 50%; /* Sets width of data cells */
}

/* School Page:  */

.schoolpage-info {
    padding: 20px 0px; 
}
.schoolpage-list {
    padding: 10px 20px; 
    color: black; 
    margin: 0 auto; 
    text-align: center; 
    display: grid;
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr; 
    gap: 0px 0px; 
}
.schoolpage-message {
    padding: 10px 20px; 
    color: black; 
    margin: 0 auto; 
    text-align: center; 
    display: grid;
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr; 
    gap: 0px 0px; 
}
.text-item {
    /* list-style: none; 
    display: flex;  */
    display: inline-block;
    vertical-align: top; 
}

/* Text Page:  */

.text-page {
    /* margin: 50px 100px;  */
    align-items: center; 
    text-align: center; 
}

.text-result {
    display: flex; 
    flex-direction: column; 
    text-align: center;
}

.text-body {
    padding: 0px 100px; 
    display: inline-block; 
}
.text-subheader {
    padding-top: 10px; 
    font-size: 25px; 
}
.text-list {
    display: flex; 
    flex-direction: column; 
    margin: 10px 0px; 
    padding: 0px; 
}
.text-line {
    list-style: none;
    font-size: 20px; 
    padding: 10px 0px; 
    word-spacing: 5px; 
    text-align: left; 
}


/* People Page:  */

.people-list {
    display: flex; 
    padding: 20px 0px; 
}
.people-item {
    display: flex; 
    flex-direction: column;
    align-items: top; 
    margin: 10px 20px; 
    transition: transform 450ms; 
}
.people-item:hover {
    cursor: pointer; 
    transform: scale(1.08);
}
.people-link {
    text-decoration: none;
}
.people-card {
    /* background-color: blue;  */
    width: 180px; 
    height: 250px; 
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    /* transition: transform 450ms;  */
}
.people-card:hover {
    cursor: pointer; 
    transform: scale(1.08);
}
.people-info {
    width: 150px; 
    padding: 5px 0px; 
    font-size: 16px; 
    font-weight: 500; 
}

/* About Page */

.text-box {
    width: 80%;
}

/* Contact Page */

.contact-form {
    width: 70%; /* Adjust as needed for mobile */
    max-width: 600px; 
    margin: auto; /* Center the form */
    /* Additional styles */
}

.form-group label {
    display: flex; 
    text-align: left; 
}

.form-control {
    padding-left: 0; /* Align text in input with label */
}

/* FOCUS STYLING - Bootstrap will override some of these */

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #F8F9FA;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a:focus,
.form-control:focus,
input[type=radio]:focus,
.btn:focus {
  outline-color: rgba(248, 249, 250) !important;
  outline-width: 1px;
  outline-style: solid;
}

/* FOCUS STYLING - Bootstrap to use in tangent with above */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: rgba(248, 249, 250, 0.8) !important;
  box-shadow: 0 1px 1px rgba(248, 249, 250, 0.075) inset, 0 0 8px rgba(248, 249, 250, 0.6) !important;
  /* transform: scale(1.02); */
  outline-color: rgba(248, 249, 250) !important;
  outline-width: 3px;
  outline-style: solid;
}

/* Makes it clear that a button has focus */
.header-nav--nav .header-nav--main .form-inline .btn-default:focus,
form button.btn:focus {
  background-color: rgba(248, 249, 250) !important;
  color: #ffffff;
}
